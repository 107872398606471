import parse from 'csv-parse/lib/sync';
import React, { useState } from 'react';
import {
  createTimeslot,
  createUser,
  getUserIdWithPhoneNumber,
} from '../../../api/firestore';

async function createTimeSlotFromBookings(dateTime, uploadedBookings) {
  const bookings = [];
  for (const booking of uploadedBookings) {
    const user = {};
    user['firstName'] = booking['First Name'];
    user['lastName'] = booking['Last Name'];
    user['email'] = booking['Email'];

    const phoneDigits = booking['Phone'].match(/\d+/g);
    user['phoneNumber'] = `+${phoneDigits.join('')}`;

    bookings.push({ user, seats: booking['Party Size'] });
  }

  // Make sure all user phone numbers are associated with a user id so that
  // we can make a booking for the user
  for (const booking of bookings) {
    // Check if a user exists. This will return null if it does not exist.
    let userId = await getUserIdWithPhoneNumber(booking.user['phoneNumber']);

    if (!userId) {
      userId = await createUser({ ...booking.user });
    }

    booking.user['id'] = userId;
  }

  // TODO: Check if a timeslot exists.
  // Actually for now, just always create a new timeslot
  const timeslotbookings = [];
  for (const booking of bookings) {
    timeslotbookings.push({ userId: booking.user.id, seats: booking.seats });
  }

  await createTimeslot({ datetime: dateTime, bookings: timeslotbookings });
}

function parseDateTimeFromBooking(booking) {
  // Need to get the date time in the YYYY-MM-DDThh:mm format
  const time = booking['Time'];
  const regex = /\d+/g;
  const matches = time.match(regex);

  let formattedTime = '';
  if (time.includes('PM')) {
    const hours = parseInt(matches[0]) + 12;
    formattedTime = `${hours}:${matches[1]}:00`;
  } else {
    let hours = parseInt(matches[0]);
    if (hours < 10) {
      formattedTime = `0${hours}:${matches[1]}:00`;
    } else {
      formattedTime = `${hours}:${matches[1]}:00`;
    }
  }

  return new Date(`${booking['Date']}T${formattedTime}`);
}

function TimeSlot(props) {
  const bookingItems = props.bookings.map((booking) => (
    <div
      className="booking"
      key={booking['First Name'] + booking['Date'] + booking['Time']}
    >
      {booking['First Name']} {booking['Last Name']} {booking['Date']}
      {booking['Time']} {booking['Email']}
      {booking['Party Size']}
      {booking['Phone']}
    </div>
  ));
  return (
    <div>
      <h3>
        {props.dateTime}
        <span>
          <button
            onClick={() =>
              createTimeSlotFromBookings(props.dateTime, props.bookings)
            }
          >
            dfd
          </button>
        </span>
      </h3>
      <div className="bookings-list">{bookingItems}</div>
    </div>
  );
}

function UploadedBookings(props) {
  if ((props.bookings?.length ?? 0) < 1) {
    return <div>No bookings</div>;
  }

  const bookingsByDateTime = {};
  props.bookings.forEach((booking) => {
    // This is fine for now because everything is pacific time.
    const dateTime = parseDateTimeFromBooking(booking);
    if (!(dateTime in bookingsByDateTime)) {
      bookingsByDateTime[dateTime] = [];
    }
    bookingsByDateTime[dateTime].push(booking);
  });

  const bookingDateTimes = Object.keys(bookingsByDateTime);
  bookingDateTimes.sort();

  const timeSlots = [];
  for (const dateTime of bookingDateTimes) {
    timeSlots.push(
      <TimeSlot
        dateTime={dateTime}
        bookings={bookingsByDateTime[dateTime]}
        key={dateTime}
      ></TimeSlot>
    );
  }

  return <div>{timeSlots}</div>;
}

export function TockUpload(props) {
  const [uploadedBookings, setUploadedBookings] = useState([]);

  const onBookingsUpload = (files) => {
    if (files.length !== 1) {
      console.error('expected one file to be uploaded');
      return;
    }

    const file = files[0];

    const reader = new FileReader();
    reader.onload = (readFile) => {
      const records = parse(readFile.target.result, {
        columns: true,
        skip_empty_lines: true,
        relax_column_count: true,
      });

      setUploadedBookings(
        records.map((record) => {
          return { ...record };
        })
      );
    };

    reader.readAsText(file);
  };

  return (
    <div>
      <h1>Tock upload</h1>
      <div>Upload timeslot</div>
      <input
        onChange={(e) => onBookingsUpload(e.target.files)}
        type="file"
      ></input>
      <UploadedBookings bookings={uploadedBookings}></UploadedBookings>
    </div>
  );
}

// Upload file
// determine timeslot date time. If one does not exist, create it, else use it
// determine user to add to timeslot. IF a user doesn't exist for the phone number, create a new user.
