import 'firebase/functions';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { sendText } from '../../../api/cloudFunctions';
import {
  fetchTemplates,
  selectAllMessages,
} from '../common/messageTemplatesSlice';
import { substituteTemplate } from '../common/messageTemplateUtils';
import { fetchTimeslots, selectAllTimeslots } from '../common/timeslotSlice';
import { fetchUsers, selectAllUsers } from '../common/usersSlice';
import './sendMessage.scss';
import {
  deselectUser,
  selectUser,
  setMessage,
  setTimeslot,
  setToken,
} from './sendMessageSlice';

function SelectMessage(props) {
  const dispatch = useDispatch();

  const onSelectedMessageChanged = function (messageId) {
    const selectedMsg = props.messages.find(
      (message) => messageId === message.id
    );
    dispatch(setMessage(selectedMsg));
  };

  const messages = props.messages.map((message) => (
    <option key={message.id} value={message.id}>
      {message.template}
    </option>
  ));
  return (
    <select
      className="select-message"
      onChange={(e) => onSelectedMessageChanged(e.target.value)}
    >
      <option key="0" value={undefined}>
        Select a message
      </option>
      {messages}
    </select>
  );
}

function timeslotDatetime(utc) {
  const d = new Date(utc);
  return d.toLocaleString();
}

// We need users so that we can pass it in to the store.
function SelectTimeslot(props) {
  const dispatch = useDispatch();

  const onSelectedTimeslotChanged = function (timeslotId) {
    const selectedTimeslot = props.timeslots.find(
      (timeslot) => timeslotId === timeslot.id
    );
    dispatch(setTimeslot({ timeslot: selectedTimeslot, users: props.users }));
  };

  const timeslots = props.timeslots.map((timeslot) => (
    <option key={timeslot.id} value={timeslot.id}>
      {timeslotDatetime(timeslot.datetime)}
    </option>
  ));
  return (
    <select onChange={(e) => onSelectedTimeslotChanged(e.target.value)}>
      <option key="0" value={undefined}>
        Select a timeslot
      </option>
      {timeslots}
    </select>
  );
}

function UserItem(props) {
  const user = props.user;

  const checkboxChange = (checked) => props.onCheckboxChange(user.id, checked);
  return (
    <div>
      <input
        type="checkbox"
        id={user.id}
        checked={props.checked}
        onChange={(e) => checkboxChange(e.target.checked)}
      ></input>
      <label htmlFor="1">
        {user.firstName} {user.lastName}
      </label>
    </div>
  );
}

function SelectUsers(props) {
  const dispatch = useDispatch();
  const selectedUsers = useSelector((state) => state.sendMessage.users);

  const onCheckboxChange = function (userId, isChecked) {
    const user = props.users.find((user) => user.id === userId);
    // Handle not able to find user.

    if (isChecked) {
      dispatch(selectUser(user));
    } else {
      dispatch(deselectUser(user));
    }
  };

  const isUserChecked = (user) =>
    selectedUsers.some((selectedUser) => selectedUser.id === user.id);

  const userItems = props.users.map((user) => (
    <UserItem
      key={user.id}
      user={user}
      onCheckboxChange={onCheckboxChange}
      checked={isUserChecked(user)}
    />
  ));

  return <div>{userItems}</div>;
}

function TemplateReplacement(props) {
  const message = useSelector((state) => state.sendMessage.message);
  if (message === undefined) return '';

  return <div>{message.template}</div>;
}

function MessageDraft(props) {
  const dispatch = useDispatch();

  const template = props.template;
  const userId = props.userId;
  const messageDraft = props.messageDraft;

  const onTokenChange = function (token, sub) {
    dispatch(setToken({ userId, token, sub }));
  };

  let tokenInputs = [];
  for (const [token, sub] of Object.entries(messageDraft.subs)) {
    tokenInputs.push(
      <div className="token-input" key={`${messageDraft.phoneNumber}_${token}`}>
        <label>{token}: </label>
        <input
          value={sub}
          onChange={(e) => onTokenChange(token, e.target.value)}
        ></input>
      </div>
    );
  }

  return (
    <div className="message-draft" key={messageDraft.phoneNumber}>
      <div className="subbed-message">
        {substituteTemplate(template, messageDraft.subs)}
      </div>
      <div>{tokenInputs}</div>
    </div>
  );
}

function MessageDrafts(props) {
  const message = useSelector((state) => state.sendMessage.message);
  const messageDrafts = useSelector((state) => state.sendMessage.messageDrafts);

  let htmlMessageDrafts = [];
  for (const [userId, messageDraft] of Object.entries(messageDrafts)) {
    htmlMessageDrafts.push(
      <MessageDraft
        template={message.template}
        userId={userId}
        messageDraft={messageDraft}
      />
    );
  }

  return <div>{htmlMessageDrafts}</div>;
}

function SendMessages(props) {
  const message = useSelector((state) => state.sendMessage.message);
  const messageDrafts = useSelector((state) => state.sendMessage.messageDrafts);

  const onClickSend = function () {
    for (const [userId, messageDraft] of Object.entries(messageDrafts)) {
      console.log(userId);
      console.log(substituteTemplate(message.template, messageDraft.subs));
      console.log(messageDraft.phoneNumber);

      sendText(
        messageDraft.phoneNumber,
        substituteTemplate(message.template, messageDraft.subs),
        userId
      );
    }
  };

  return <button onClick={onClickSend}>Send!</button>;
}

export function SendMessage() {
  const dispatch = useDispatch();
  const messages = useSelector(selectAllMessages);
  const messageStatus = useSelector((state) => state.messageTemplates.status);

  const timeslots = useSelector(selectAllTimeslots);
  const timeslotStatus = useSelector((state) => state.timeslots.status);

  const users = useSelector(selectAllUsers);
  const usersStatus = useSelector((state) => state.users.status);

  // Load messages on component load.
  useEffect(() => {
    if (messageStatus === 'idle') {
      dispatch(fetchTemplates());
    }
  }, [messageStatus, dispatch]);

  // Load users on component load.
  useEffect(() => {
    if (timeslotStatus === 'idle') {
      dispatch(fetchTimeslots());
    }
  }, [timeslotStatus, dispatch]);

  // Load users on component load.
  useEffect(() => {
    if (usersStatus === 'idle') {
      dispatch(fetchUsers());
    }
  }, [usersStatus, dispatch]);

  return (
    <div className="send-message-page">
      <h1>Send Message</h1>
      <h2>(1) Select a message</h2>
      <SelectMessage messages={messages} />
      <h2>(2) Timeslot or User(s)?</h2>
      <SelectTimeslot timeslots={timeslots} users={users} />
      <SelectUsers users={users} />
      <h2>(3) Template replacement</h2>
      <TemplateReplacement />

      <h2>(4) Preview</h2>
      <MessageDrafts />
      <h2>(5) Send</h2>
      <SendMessages />
    </div>
  );
}
