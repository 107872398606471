import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createUser, fetchUsers, selectAllUsers } from '../common/usersSlice';
import './userAdmin.scss';

function UserItem(props) {
  let user = props.user;
  return (
    <div className="user-item" onClick={(e) => props.onClickUserItem(user)}>
      {user.firstName} {user.lastName}
    </div>
  );
}

function UserItems(props) {
  const users = props.users;
  const userItems = users.map((user) => (
    <UserItem
      key={user.id}
      user={user}
      onClickUserItem={props.onClickUserItem}
    ></UserItem>
  ));
  return <div className="user-items">{userItems}</div>;
}

function SelectedUser(props) {
  const fields = [];
  for (const key in props.user) {
    fields.push(
      <div>
        {key}: {props.user[key]}
      </div>
    );
  }
  return <div>{fields}</div>;
}

function CreateUser(props) {
  const dispatch = useDispatch();

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');

  const onCreateUser = () => {
    dispatch(createUser({ firstName, lastName, phoneNumber }));
  };

  return (
    <div className="create-new-user">
      <div className="create-new-user">
        <label htmlFor="first-name">First name</label>
        <input
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
          type="text"
          name="first-name"
          id="first-name"
        ></input>
      </div>
      <div className="create-new-user">
        <label htmlFor="last-name">Last name</label>
        <input
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
          type="text"
          id="last-name"
        ></input>
      </div>
      <div className="create-new-user">
        <label htmlFor="phone-number">Phone number</label>
        <input
          value={phoneNumber}
          onChange={(e) => setPhoneNumber(e.target.value)}
          type="tel"
          id="phone-number"
        ></input>
      </div>
      <button onClick={(e) => onCreateUser()} className="create-user-button">
        Create new user
      </button>
    </div>
  );
}

export function UserAdmin() {
  const dispatch = useDispatch();
  const users = useSelector(selectAllUsers);
  const usersStatus = useSelector((state) => state.users.status);

  // Load users on component load.
  useEffect(() => {
    if (usersStatus === 'idle') {
      dispatch(fetchUsers());
    }
  }, [usersStatus, dispatch]);

  const [selectedUser, setSelectedUser] = useState(users[0]);
  const onClickUserItem = (user) => setSelectedUser(user);

  return (
    <div>
      <div className="title">User Admin</div>
      <div className="user-admin">
        <UserItems users={users} onClickUserItem={onClickUserItem} />
        <div className="select-user">
          <SelectedUser user={selectedUser} />
        </div>
      </div>
      <CreateUser />
    </div>
  );
}
