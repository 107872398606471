import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as client from '../../../api/firestore';

export const fetchTimeslots = createAsyncThunk(
  'timeslots/fetchAll',
  async () => {
    const response = await client.loadTimeslots();
    return response;
  }
);

export const createTimeslot = createAsyncThunk(
  'timeslots/create',
  async (timeslot) => {
    const id = await client.createTimeslot(timeslot);
    return { ...timeslot, id };
  }
);

// Id is expected to be a field on the input timeslot referring to its DB id.
export const updateTimeslot = createAsyncThunk(
  'timeslots/update',
  async (timeslot) => {
    // Pass in new object into updateTimeslot.
    await client.updateTimeslot({ ...timeslot });
    return { ...timeslot };
  }
);

export const timeslotsSlice = createSlice({
  name: 'timeslots',
  initialState: {
    timeslots: [],
    status: 'idle',
    error: null,
  },
  extraReducers: {
    [fetchTimeslots.pending]: (state, action) => {
      state.status = 'loading';
    },
    [fetchTimeslots.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      // Add any fetched timeslots to the array
      state.timeslots = state.timeslots.concat(action.payload);
    },
    [fetchTimeslots.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    },
    [createTimeslot.fulfilled]: (state, action) => {
      state.timeslots.push(action.payload);
    },
    [updateTimeslot.fulfilled]: (state, action) => {
      const updatedTimeslot = action.payload;

      const index = state.timeslots.findIndex(
        (timeslot) => timeslot.id === updatedTimeslot.id
      );

      if (index === -1) {
        console.log("Tried to update timeslot that doesn't exist");
        return;
      }
      state.timeslots[index] = { ...updatedTimeslot };
    },
  },
});

export default timeslotsSlice.reducer;

export const selectAllTimeslots = (state) => state.timeslots.timeslots;
