import './bookingPage.scss';

import bannerImage from './TikiMirageBanner_small.png';

export function BookingPage() {
  return (
    <div className="booking-page">
      <div className="banner-wrapper">
        <img className="banner" src={bannerImage} alt="Tiki Mirage" />
      </div>
      <div className="page-title">
        <b>Book Tiki Mirage</b>
        <hr color="#ecd9ac" width="60%"></hr>
      </div>
      <div className="google-form">
        <iframe
          src="https://docs.google.com/forms/d/e/1FAIpQLSdVGgqYbi3LNTrmSCMk7dpjCmUDa0uzwpu4gFp-PTYvrU1TXw/viewform?embedded=true"
          width="100%"
          height="2293"
          frameborder="0"
          marginheight="0"
          marginwidth="0"
        >
          Loading…
        </iframe>
      </div>
    </div>
  );
}
