import { useState } from 'react';
import PhoneInput from 'react-phone-number-input/input';
import { addWaitlistEntry } from '../../api/firestore';
import joinWaitlistButton from './assets/joinWaitlist.png';
import seeYouInParadise from './assets/seeYouInParadise.png';
import vipJoinWaitlistButton from './assets/vipJoinWaitlist.png';
import './joinWaitlist.scss';

function WaitlistForm(props) {
  const [name, setName] = useState('');
  const [phonenumber, setPhonenumber] = useState('');
  const [email, setEmail] = useState('');

  const isSubmitEnabled = () => {
    console.log(phonenumber);
    if ((name?.length ?? 0) <= 0) return false;
    // Formatted with +1
    if ((phonenumber?.length ?? 0) < 11) return false;

    return true;
  };

  const handleSubmit = (event) => {
    const currentDate = new Date();
    const utcTime = currentDate.toUTCString();
    const date = currentDate.toLocaleDateString();

    addWaitlistEntry({ name, phonenumber, email, date, utcTime });

    setName('');
    setPhonenumber('');
    setEmail('');

    props.onSubmit();
    event.preventDefault();
  };

  const buttonImage = props.useVIPButton
    ? vipJoinWaitlistButton
    : joinWaitlistButton;

  return (
    <form className="join-waitlist-form" onSubmit={handleSubmit}>
      <div className="inputs">
        <input
          id="name"
          className="waitlist-input"
          type="text"
          placeholder="Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
        ></input>
        <PhoneInput
          className="waitlist-input email-input"
          placeholder="Phone Number"
          country="US"
          value={phonenumber}
          onChange={setPhonenumber}
        />
        <input
          id="email"
          className="waitlist-input"
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        ></input>
        <button
          className="join-button"
          type="submit"
          disabled={!isSubmitEnabled()}
        >
          <img src={buttonImage} alt="Join The Waitlist" />
        </button>
      </div>
    </form>
  );
}

// inputs:
// useVIPButton: bool : whether to use the Join vip waitlist button.
export function JoinWaitlist(props) {
  const [submitted, setSubmitted] = useState(false);

  const useVIPButton = props?.useVIPButton ?? false;

  // TODO: Implement action data.
  // Every action has a payload of data associated with it for
  // the specific action
  // const actionData = props.data;

  if (submitted) {
    return (
      <div className="waitlist-confirmation">
        <img
          className="paradise-img"
          src={seeYouInParadise}
          alt="See You In Paradise..."
        />
      </div>
    );
  }

  return (
    <div className="join-waitlist">
      <WaitlistForm
        onSubmit={() => setSubmitted(true)}
        useVIPButton={useVIPButton}
      />
    </div>
  );
}
