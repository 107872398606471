import { createAction } from '../../../api/firestore';
import { useSimpleInputValues } from '../../../common/hooks';
import './action.scss';

function createWaiverAction(timeslotId) {
  // TODO: expiration date for when waiver can no longer be loaded.
  return { actionType: 'waiver', timeslotId };
}

function createJoinWaitlistAction() {
  return { actionType: 'join-waitlist' };
}

export function AdminActionManagement() {
  const { bind, reset, values } = useSimpleInputValues();

  const onClickCreateWaiverAction = function () {
    const timeslotId = values['timeslotId'] ?? '';
    console.log(`Creating waiver action with timeslotid ${timeslotId}`);
    createAction(createWaiverAction(timeslotId));
  };

  return (
    <div>
      <h3 className="page-title">Create an action</h3>

      <div className="action-definition">
        <div className="action-title"></div>
        <input
          className="timeslot id"
          type="text"
          placeholder="Timeslot id"
          {...bind('timeslotId')}
        ></input>
        <button onClick={onClickCreateWaiverAction}>
          Create waiver action
        </button>
      </div>

      <div className="action-definition">
        <button onClick={() => createAction(createJoinWaitlistAction())}>
          Create join waitlist action
        </button>
      </div>
    </div>
  );
}
