import { createSlice } from '@reduxjs/toolkit';
import { cleanedTokensFromTemplate } from '../common/messageTemplateUtils';

// Attempt to auto fill any token values that come from user or timeslot.
// Returns a DraftMessage object which contains two fields:
//  * subs which is a dictionary from cleaned token to substitution value
//  * phoneNumber to send the message to.
function createDraftMessage(cleanedTokens, user, timeslot) {
  // Dictionary of a cleaned token to its subbed string.
  const subDictionary = {};

  for (const token of cleanedTokens) {
    // Ignore timeslot if not passed one as a parameter.
    if (token.includes('timeslot.') && timeslot !== undefined) {
      const sub = timeslot[token.split('.')[1]];

      if (sub !== undefined) {
        subDictionary[token] = sub;
        continue;
      }
    }

    if (token.includes('user.')) {
      const sub = user[token.split('.')[1]];

      if (sub !== undefined) {
        subDictionary[token] = sub;
        continue;
      }
    }
    subDictionary[token] = 'Value not set';
  }

  return { phoneNumber: user.phoneNumber, subs: subDictionary };
}

const sendMessageSlice = createSlice({
  name: 'sendMessage',
  initialState: {
    message: undefined,
    timeslot: undefined,
    users: [], // TODO: rename to selectedUsers
    messageDrafts: {},
  },
  reducers: {
    setMessage(state, action) {
      state.message = action.payload;

      if (state.message === undefined) return;

      // Update all messageDrafts to work with new message template.
      const cleanedTokens = cleanedTokensFromTemplate(state.message.template);
      for (const user of state.users) {
        state.messageDrafts[user.id] = createDraftMessage(
          cleanedTokens,
          user,
          state.timeslot
        );
      }
    },
    setTimeslot(state, action) {
      // The timeslot object only has user ids, but we need the full user object.
      // We pass in all users in the action payload, and search for them
      // TODO: Make searching for the users faster.

      const timeslot = action.payload.timeslot;
      state.timeslot = timeslot;

      // Clear previous data. Just reset everything because we can't be sure.
      state.messageDrafts = {};
      state.users = [];

      // No template to templatize.
      if (state.message === undefined) return;
      const cleanedTokens = cleanedTokensFromTemplate(state.message.template);

      // Select each user from timeslot and create a draft message for each one.
      console.log(timeslot);
      for (const booking of timeslot.bookings) {
        const userId = booking.userId;
        const user = action.payload.users.find((user) => user.id === userId);
        state.users.push(user);

        state.messageDrafts[userId] = createDraftMessage(
          cleanedTokens,
          user,
          timeslot
        );
      }
    },
    selectUser(state, action) {
      const user = action.payload;
      state.users.push(user);

      // No template to templatize.
      if (state.message === undefined) return;
      const cleanedTokens = cleanedTokensFromTemplate(state.message.template);

      state.messageDrafts[user.id] = createDraftMessage(cleanedTokens, user);
    },
    deselectUser(state, action) {
      const user = action.payload;
      state.users = state.users.filter((u) => u.id !== user.id);
      delete state.messageDrafts[user.id];
    },
    setToken(state, action) {
      state.messageDrafts[action.payload.userId].subs[action.payload.token] =
        action.payload.sub;
    },
  },
});

export const { setMessage, setTimeslot, selectUser, deselectUser, setToken } =
  sendMessageSlice.actions;
export default sendMessageSlice.reducer;
