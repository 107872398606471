export const waiverText = (
  <div>
    <center>Tiki Mirage Release of Liability and Assumption of Risk</center>
    <p>
      The individual named below (referred to as “I” or “me”) desires to
      participate in the Tiki Mirage experience (the “Experience”) provided by
      Future Leisure Foundation, Inc., a California corporation (“FLF”). In
      consideration of the right to participate in the Experience and in
      recognition of FLF’s reliance hereon, I agree to all the terms and
      conditions set forth in this instrument (this “Release”).
    </p>
    <p>
      <u>Waiver.</u> I hereby expressly waive and release any and all claims
      which I may have, or which I may hereafter have, whether known or unknown,
      against FLF, and its officers, directors, managers, employees, agents,
      affiliates, shareholders, successors, and assigns (collectively,
      “Releasees”), on account of injury, disability, death, or property damage
      arising out of or attributable to my participation in the Experience,
      whether arising out of the ordinary negligence of FLF or any Releasees or
      otherwise. I covenant not to make or bring any such claim against FLF or
      any other Releasee, and forever release and discharge FLF and all other
      Releasees from liability under such claims. This waiver and release does
      not extend to claims for gross negligence, intentional or reckless
      misconduct, or any other liabilities that California law does not permit
      to be released by agreement.
    </p>
    <p>
      <u>Legal Drinking Age</u>. I acknowledge that I will be consuming alcohol
      while taking part in the Experience and that I must be of legal drinking
      age, 21, to access and participate in the Experience. By participating, I
      represent that I am at least 21 years of age. I acknowledge that FLF has
      made every reasonable effort to ensure that alcoholic beverages are not
      served to anyone who is under the age of 21.
    </p>
    <p>
      <u>Warranties and Representations.</u> I acknowledge that FLF makes no
      guarantees, representations or warranties, whether express or implied,
      regarding any food or drink I may consume. Any ill effect from any food or
      drink, including but not limited to food poisoning, intoxication,
      sickness, or allergic reaction shall not be the fault or responsibility of
      FLF or any Releasees.
    </p>
    <p>
      <u>Assumption of Risk.</u> I acknowledge that this Experience may carry
      with it the potential for property loss, physical or psychological injury,
      and death. I understand that these injuries or outcomes may arise from my
      own or others’ negligence and represent that I am in good health and
      suffer from no physical or mental injury, illness or disability that would
      make me especially susceptible to injury or disability while participating
      in the Experience. I expressly and voluntarily assume any risk related to
      the consumption of food or drink during the Experience. In the event that
      I should require medical care or treatment during or after the Experience,
      I agree to be financially responsible for any costs incurred as a result
      thereof.
    </p>
    <p>
      I acknowledge that this Experience may carry with it the potential for
      property loss, physical or psychological injury, and death. I understand
      that these injuries or outcomes may arise from my own or others’
      negligence and represent that I am in good health and suffer from no
      physical or mental injury, illness or disability that would make me
      especially susceptible to injury or disability while participating in the
      Experience. I expressly and voluntarily assume any risk related to the
      consumption of food or drink during the Experience. In the event that I
      should require medical care or treatment during or after the Experience, I
      agree to be financially responsible for any costs incurred as a result
      thereof.
    </p>
    <p>
      <b>
        WITH THE INTENTION OF WAIVING ALL UNKNOWN AND UNSUSPECTED CLAIMS, I
        HEREBY EXPRESSLY WAIVE ALL RIGHTS, BENEFITS, AND PROTECTIONS I MAY HAVE
        UNDER CALIFORNIA CIVIL CODE SECTION 1542, WHICH READS AS FOLLOWS:
      </b>
    </p>
    <p>
      <b>
        “A general release does not extend to claims that the creditor or
        releasing party does not know or suspect to exist in his or her favor at
        the time of executing the release and that, if known by him or her,
        would have materially affected his or her settlement with the debtor or
        released party.”
      </b>
    </p>
    <p>
      <u>Reasonable Care.</u> During the Experience, I agree to act responsibly
      and to use reasonable care. I agree to indemnify and hold harmless FLF and
      all Releasees from and against any and all claims, suits or actions of any
      kind whatsoever for liability, damages, compensation or otherwise,
      including attorney’s fees and any related costs, which arise in any part
      by my actions or omissions.
    </p>
    <p>
      <u>Dramshop/Social Host Liability.</u> I acknowledge that FLF and all
      Releasees shall not be liable for any harm or injury I may inflict upon
      others as a result of my consumption of alcohol during the Experience,
      whether such harm or injury occurs during or after the Experience.
      Further, I expressly acknowledge and understand the meaning of California
      Civil Code 1714, which reads as follows:
    </p>
    <p>
      “[T]he furnishing of alcoholic beverages is not the proximate cause of
      injuries resulting from intoxication, but rather the consumption of
      alcoholic beverages is the proximate cause of injuries inflicted upon
      another by an intoxicated person.”
    </p>
    <p>
      <u>COVID-19</u> I acknowledge that an express condition of my
      participating in the Experience is that I have been fully vaccinated
      against COVID-19. I further acknowledge that FLF has taken all reasonable
      and legally required measures to ensure compliance with public health
      guidelines in light of the ongoing COVID-19 pandemic. By participating in
      the Experience, I represent that I have not intentionally or
      unintentionally misrepresented my vaccination status to obtain access to
      the Experience. Notwithstanding the aforementioned measures taken by FLF
      to protect against COVID-19, I hereby expressly assume the risk of
      COVID-19 infection during the Experience and agree to hold FLF and all
      Releasees harmless from and against any and all claims arising therefrom.
    </p>
    <p>
      <u>Right to Name and Likeness.</u> I grant to FLF and FLF’s successors and
      assigns an irrevocable, sub-licensable right and authority to use my name,
      likeness, photograph and/or picture for any and all commercial or
      non-commercial purposes now known or later developed in perpetuity without
      further obligation or compensation to me.
    </p>
    <p>
      This Release constitutes the sole and entire agreement of FLF and me with
      respect to the subject matter contained herein and supersedes all prior
      and contemporaneous understandings, agreements, representations, and
      warranties, both written and oral, with respect to such subject matter. If
      any term or provision of this Release is held invalid, illegal, or
      unenforceable in any jurisdiction, such invalidity, illegality, or
      unenforceability shall not affect any other term or provision of this
      Release or invalidate or render unenforceable such term or provision in
      any other jurisdiction. This Release is binding on and shall inure to the
      benefit of FLF and me and their respective heirs, successors, and assigns.
      All matters arising out of or relating to this Release shall be governed
      by and construed in accordance with the internal laws of the State of
      California without giving effect to any choice or conflict of law
      provision or rule (whether of the State of California or any other
      jurisdiction). Any claim or cause of action arising under this Release may
      be brought only in the federal and state courts located in Los Angeles
      County, California and I hereby consent to the exclusive jurisdiction of
      such courts.
    </p>
  </div>
);
