import React from 'react';
import {
  Redirect,
  Route,
  BrowserRouter as Router,
  Switch,
  useParams,
} from 'react-router-dom';
import './App.scss';
import { ProvideAuth, useAuth } from './common/auth';
import { Action } from './features/actions/actions';
import { AdminPage } from './features/admin/adminPage/adminPage';
import { BookingPage } from './features/bookingPage/bookingPage';
import { JoinWaitlist } from './features/joinWaitlist/joinWaitlist';
import { TikiHome } from './features/tikiHome/tikiHome';
import { UserLogin } from './features/user_login/UserLogin';

export default function App() {
  return (
    <ProvideAuth>
      <Router>
        <div className="router">
          {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
          <Switch>
            <Route path="/login">
              <UserLogin />
            </Route>

            <PrivateRoute path="/admin">
              <AdminPage />
            </PrivateRoute>

            <Route path="/action/:id">
              <ActionRoute />
            </Route>

            <Route path="/JoinWaitlist">
              <JoinWaitlist />
            </Route>

            <Route path="/tiki">
              <TikiHome />
            </Route>

            <Route path="/book">
              <BookingPage />
            </Route>

            <Route path="/">
              <TikiHome />
            </Route>
          </Switch>
        </div>
      </Router>
    </ProvideAuth>
  );
}

function ActionRoute(props) {
  let { id } = useParams();
  return <Action actionId={id} />;
}

function PrivateRoute({ children, ...rest }) {
  let auth = useAuth();
  return auth.loadingAuth ? (
    'loading...'
  ) : (
    <Route
      {...rest}
      render={({ location }) =>
        auth.user ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              // Enables redirects after successful login.
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}
