import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { deleteTemplate, saveTemplate } from '../common/messageTemplatesSlice';
import './active_message.scss';

function EditMessage(props) {
  const onNameChange = (e) => props.onNameChange(e.target.value);
  const onTemplateChange = (e) => props.onTemplateChange(e.target.value);

  return (
    <div className="edit-message">
      <div className="name">
        <div className="input-title">Name:</div>
        <input
          className="name-input"
          value={props.name}
          onChange={onNameChange}
        ></input>
      </div>
      <div className="template">
        <textarea
          className="template-input"
          value={props.template}
          onChange={onTemplateChange}
        ></textarea>
      </div>
    </div>
  );
}

function TestMessageInput(props) {
  const token = props.token;

  const [tokenValue, setTokenValue] = useState('');
  const onTokenValueChange = (e) => {
    const value = e.target.value;
    props.onChange({ token: token, value: value });
    setTokenValue(value);
  };

  return (
    <div>
      <div>{token}:</div>
      <input
        className="token-input"
        value={tokenValue}
        onChange={onTokenValueChange}
      ></input>
    </div>
  );
}

// Takes a template and returns the cleaned tokens
// 'I like to eat {{food1}} and {{food2}}' => ['food1', 'food2']
// TODO: Figure out how to ignore /{ (ignore escaped bracket)
function cleanedTokensFromTemplate(template) {
  if (template === '') return [];
  if (!template) return [];

  // Matches characters surrounded by double brackets ({{}})/
  // The .* matches any character and the ? enables lazy evaluation.
  const tokensRegEx = /{{.*?}}/g;

  // Tokens in the form of {{token}}
  const tokens = template.match(tokensRegEx) ?? [];

  const cleanedTokens = [];
  for (const token of tokens) {
    cleanedTokens.push(token.substring(2, token.length - 2));
  }
  return cleanedTokens;
}

function SubbedTemplate(props) {
  let subbedTemplate = props.template;
  const substitutions = props.substitutions ?? {};

  for (let [key, value] of Object.entries(substitutions)) {
    subbedTemplate = subbedTemplate.replaceAll(`{{${key}}}`, value);
  }

  return <div className="subbed-template">{subbedTemplate}</div>;
}

function TestMessage(props) {
  const template = props.template;

  // Object that holds key, values of substitutions
  const [substitutions, setSubstitutions] = useState({});

  // Clear substitutions when temaplte prop changes
  useEffect(() => {
    setSubstitutions({});
  }, [props.template]);

  // Updates the subbed template when test inputs are changed
  const onTestMessageInputChange = (input) => {
    let token = input['token'];
    let value = input['value'];

    let copyState = { ...substitutions, [token]: value };
    setSubstitutions(copyState);
  };

  const tokenInputs = [];
  // Use set so we only have unique tokens.
  const cleanedTokens = new Set(cleanedTokensFromTemplate(template));
  for (const token of cleanedTokens) {
    const html = (
      <TestMessageInput
        key={token}
        token={token}
        onChange={onTestMessageInputChange}
      ></TestMessageInput>
    );
    tokenInputs.push(html);
  }

  return (
    <div className="test-message">
      <SubbedTemplate
        template={template}
        substitutions={substitutions}
      ></SubbedTemplate>
      <div className="tokens">{tokenInputs}</div>
    </div>
  );
}

// Data needed to look up info to add to the message
// For example, the timeslot for which this message should be sent to.
// function QueryParameter(props) {
//   const [parameters, setParameters] = useState(props.queryParameters ?? []);
//   const queryParametersItems = parameters.map((message) => <div>bdf</div>);

//   return (
//     <div>
//       <div className="title">Query parameters</div>
//       <div className="query-parameters">
//         {queryParametersItems}
//         <div className="keys"></div>
//         <div className="values"></div>
//         <button
//           className="add-query-parameter-button"
//           onClick={() =>
//             setParameters(() => {
//               let x = Array.from(parameters);
//               x.push([]);
//               return x;
//             })
//           }
//         >
//           Add query parameter
//         </button>
//       </div>
//     </div>
//   );
// }

export function ActiveMessage(props) {
  const dispatch = useDispatch();

  const [name, setName] = useState(props.message.name);
  const [template, setTemplate] = useState(props.message.template);

  // useEffect is called after the component is rendered, and checks if any of the
  // source paramters (values in the second arg array) have changed. If so, it calls
  // the function input as the first arg.
  //
  // Used here because we need to have the name/template state to allow the user
  // to easily update them, but then also need to change them if the active message
  // is changed.
  // useEffect(() => {
  //   setName(props.message.name);
  //   setTemplate(props.message.template);
  // }, [props.message.name, props.message.template, props.message.id]);

  const onSaveClicked = () => {
    dispatch(saveTemplate({ ...props.message, name, template }));
  };

  return (
    <div className="active-message">
      <div className="row">
        <EditMessage
          name={name}
          onNameChange={setName}
          template={template}
          onTemplateChange={setTemplate}
          key={props.message.id}
        ></EditMessage>
        <TestMessage template={template}></TestMessage>
      </div>

      {/* <QueryParameter
        queryParameters={props.message.queryParameters}
      ></QueryParameter> */}

      <button className="save-button" onClick={() => onSaveClicked()}>
        Save
      </button>
      <button
        className="delete-button"
        onClick={() => dispatch(deleteTemplate(props.message))}
      >
        Delete
      </button>
    </div>
  );
}
