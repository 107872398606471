import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as client from '../../../api/firestore';

// import * as fake_client from '../../fake_api/message_templates';

export const fetchTemplates = createAsyncThunk(
  'messageTemplates/fetchAll',
  async () => {
    // const response = await client.loadMessageTemplates();
    const response = await client.loadMessageTemplates();
    return response;
  }
);

export const deleteTemplate = createAsyncThunk(
  'messageTemplates/deleteMessage',
  async (template) => {
    // It was never saved, so no need to hit server.
    if (template.id < 0) return template;

    await client.deleteMessageTemplate(template);
    // Return template here because we need it in the fulfulled action
    // to delete it from the UI
    return template;
  }
);

// Saves the template.
//
// If the template was previously unsaved, the
// api will return with a new id. The returned field prevId can be used
// to identify this case.
export const saveTemplate = createAsyncThunk(
  'messageTemplates/updateMessage',
  async (template) => {
    // Unsaved messages are given id's less than 0.
    let tempId = parseInt(template.id);
    if (!isNaN(tempId) && tempId < 0) {
      // Saving a new template
      const response = await client.addMessageTemplate(template);
      return { newId: response.id, template: template };
    }

    // Saving an existing template
    await client.updateMessageTemplate(template);
    return { template };
  }
);

// Value used when creating a new message.
let nextId = -1;

export const messageTemplatesSlice = createSlice({
  name: 'messageTemplates',
  initialState: {
    messages: [],
    status: 'idle',
    error: null,
  },
  reducers: {
    // Takes a new id as the payload.
    addNewMessage: {
      reducer(state, action) {
        state.messages.push(action.payload);
      },
      prepare() {
        return {
          payload: {
            id: nextId--,
            name: 'New message',
            template: '',
            queryParameters: [],
          },
        };
      },
    },
  },
  extraReducers: {
    [fetchTemplates.pending]: (state, action) => {
      state.status = 'loading';
    },
    [fetchTemplates.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      // Add any fetched posts to the array
      state.messages = state.messages.concat(action.payload);
    },
    [fetchTemplates.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    },
    [deleteTemplate.fulfilled]: (state, action) => {
      const index = state.messages.findIndex(
        (message) => message.id === action.payload.id
      );
      if (index === -1) {
        console.log("Tried to delete message that doens't exist");
        return;
      }
      state.messages.splice(index, 1);
    },
    [saveTemplate.fulfilled]: (state, action) => {
      // If the save was for a new message, we have to update
      // messages id.

      const template = action.payload.template;
      const index = state.messages.findIndex(
        (message) => message.id === template.id
      );
      if (index === -1) {
        console.log("Tried to update message that doesn't exist");
        return;
      }

      if (action.payload.newId) {
        state.messages[index] = { ...template, id: action.payload.newId };
      } else {
        state.messages[index] = { ...template };
      }
    },
  },
});

export const { addNewMessage, deleteMessage, updateMessage } =
  messageTemplatesSlice.actions;

export const selectAllMessages = (state) => state.messageTemplates.messages;

export default messageTemplatesSlice.reducer;
