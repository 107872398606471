import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useAuth } from '../../common/auth';
import './user_login.scss';

function ErrorDisplay(props) {
  if (props.hasError === false) return '';

  return (
    <div>
      <div>Error!</div>
      <div>{props.error.errorCode}</div>
      <div>{props.error.errorMessage}</div>
    </div>
  );
}

export function UserLogin() {
  let history = useHistory();
  let location = useLocation();

  const auth = useAuth();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [hasError, setHasError] = useState(false);
  const [error, setError] = useState({});

  let { from } = location.state || { from: { pathname: '/' } };

  const onLogIn = async () => {
    const response = await auth.signIn(email, password);

    if (response?.success === true) {
      history.replace(from);
    }

    setError({}); // Clear error.
    setHasError(false);
    if (response?.hasError === true) {
      setHasError(true);
      setError(response.error);
    }
  };

  return (
    <div className="login-card">
      <div className="title">Log in</div>

      <div className="input">
        <div className="input-title"> Email:</div>
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </div>

      <div className="input">
        <div className="input-title"> Password:</div>
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </div>

      <button className="login-buttom" onClick={onLogIn}>
        Log in!
      </button>

      <div>{email}</div>
      <div>{password}</div>
      <ErrorDisplay hasError={hasError} error={error} />
    </div>
  );
}
