// Takes a template and returns the cleaned tokens
// 'I like to eat {{food1}} and {{food2}}' => ['food1', 'food2']
// TODO: Figure out how to ignore /{ (ignore escaped bracket)
export function cleanedTokensFromTemplate(template) {
  if (template === '') return [];
  if (!template) return [];

  // Matches characters surrounded by double brackets ({{}})/
  // The .* matches any character and the ? enables lazy evaluation.
  const tokensRegEx = /{{.*?}}/g;

  // Tokens in the form of {{token}}
  const tokens = template.match(tokensRegEx) ?? [];

  const cleanedTokens = [];
  for (const token of tokens) {
    cleanedTokens.push(token.substring(2, token.length - 2));
  }
  return cleanedTokens;
}

// subsititions should be a map of a cleaned token to its substitued form
export function substituteTemplate(template, substitutions) {
  let subbedTemplate = template;
  for (let [key, value] of Object.entries(substitutions)) {
    subbedTemplate = subbedTemplate.replaceAll(`{{${key}}}`, value);
  }

  return subbedTemplate;
}
