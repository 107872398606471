import { useState } from 'react';
import bannerImage from '../../../assets/TikiMirageBanner_small.png';
import { JoinWaitlist } from '../../joinWaitlist/joinWaitlist';
import './joinWaitlistAction.scss';

export function JoinWaitlistAction(props) {
  const [submitted, setSubmitted] = useState(false);

  // TODO: Implement action data.
  // Every action has a payload of data associated with it for
  // the specific action
  // const actionData = props.data;

  return (
    <div className="vip-waitlist">
      <div className="content">
        <img
          className="tiki-mirage-banner"
          src={bannerImage}
          alt="Tiki Mirage"
        />
        <JoinWaitlist useVIPButton={true} />
      </div>
    </div>
  );
}
