// Actions allow us to associate a unique code to dynimcally load an "action"
// that allows a user to something.

import { useEffect, useState } from 'react';
import { loadAction } from '../../api/cloudFunctions';
import { JoinWaitlistAction } from './actionTypes/joinWaitlistAction';
import { WaiverAction } from './actionTypes/waiverAction';

function RenderAction(props) {
  const action = props.action;

  if (action.hasError) {
    return (
      <div className="action-error">
        <div>code: {action.error.code}</div>
        <div>message: {action.error.message}</div>
        <div>details: {action.error.details}</div>
      </div>
    );
  }

  if (
    action?.success?.action === null ||
    action?.success?.action === undefined
  ) {
    return <div></div>;
  }

  const actiontype = action.success.action.actionType;
  if (actiontype === 'waiver') {
    return <WaiverAction data={action.success.action} />;
  }
  if (actiontype === 'join-waitlist') {
    return <JoinWaitlistAction />;
  }

  return <div></div>;
}

export function Action(props) {
  const [action, setAction] = useState('');

  useEffect(() => {
    loadAction(props.actionId).then((result) => setAction(result));
  }, [props.actionId]);

  return <RenderAction action={action}></RenderAction>;

  // Load the action from the database
  // An action has an id, a userid, action type (each action will be assigned a type),
  // details (can be used to store anything needed to render the action)
}
