import React, { createContext, useContext, useEffect, useState } from 'react';
import {
  createUserWithEmail,
  currentUser,
  onAuthStateChanged,
  signInWithEmailandPassword,
} from '../api/auth';

// Hook for authentication
// See https://usehooks.com/useAuth/
// and https://reactrouter.com/web/example/auth-workflow

const authContext = createContext();

// Provider component that wraps your app and makes auth object
// available to any child component that calls useAuth().
export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}
// Hook for child components to get the auth object and re-render when it changes.
export const useAuth = () => {
  return useContext(authContext);
};

// Provider hook that creates auth object and handles state
function useProvideAuth() {
  // Will be true if the app hasn't had time yet to load an already signed in user.
  const [loadingAuth, setLoadingAuth] = useState(true);
  const [user, setUser] = useState(currentUser());

  // Wrap any Firebase methods we want to use making sure
  // to save the user to state.
  const signIn = async (email, password) => {
    const response = await signInWithEmailandPassword(email, password);

    if (response?.success === true) setUser(response.user);
    return response;
  };

  const signUp = async (email, password) => {
    return createUserWithEmail(email, password).then((response) => {
      setUser(response.user);
      return response.user;
    });
  };

  const signOut = () => {
    return signOut.then(() => {
      setUser(false);
    });
  };

  // Subscribe to user on mount
  // Because this sets state in the callback it will cause any ...
  // ... component that utilizes this hook to re-render with the ...
  // ... latest auth object.
  useEffect(() => {
    const unsubscribe = onAuthStateChanged((user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(false);
      }

      setLoadingAuth(false);
    });
    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);
  // Return the user object and auth methods
  return {
    user,
    loadingAuth,
    signIn,
    signUp,
    signOut,
  };
}
