import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as client from '../../../api/firestore';

export const fetchUsers = createAsyncThunk('users/fetchAll', async () => {
  const response = await client.loadUsers();

  return response;
});

export const createUser = createAsyncThunk('users/create', async (user) => {
  const newId = await client.createUser(user);
  return { ...user, id: newId };
});

export const usersSlice = createSlice({
  name: 'users',
  initialState: {
    users: [],
    status: 'idle',
    error: null,
  },
  extraReducers: {
    [fetchUsers.pending]: (state, action) => {
      state.status = 'loading';
    },
    [fetchUsers.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      // Add any fetched users to the array
      state.users = state.users.concat(action.payload);
    },
    [fetchUsers.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    },
    [createUser.fulfilled]: (state, action) => {
      state.users.push(action.payload);
      console.log(action.payload);
    },
  },
});

export default usersSlice.reducer;

export const selectAllUsers = (state) => state.users.users;
