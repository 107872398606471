import { useState } from 'react';
import PhoneInput from 'react-phone-number-input/input';
import { addWaiverEntry } from '../../../api/firestore';
import tikiLogo from '../../../assets/TikiMirageBanner_small.png';
import { useSimpleInputValues } from '../../../common/hooks';
import './waiverAction.scss';
import { waiverText } from './waiverText';

const agreeCheckBoxText =
  'I have read and agree to the Tiki Mirage Release of Liability and Assumption of Risk.';
const marketingCheckBoxText =
  'I would like to receive updates from Tiki Mirage about future events and ticket drops.';

function SignWaiver(props) {
  const { bind, reset, values, setValue } = useSimpleInputValues({
    agree: 'checkbox',
    optIntoMarketing: 'checkbox',
  });
  const [phoneNumber, setPhoneNumber] = useState('');

  const isSubmitEnabled = () => {
    if (values['agree'] !== true) return false;

    if ((values['firstName']?.length ?? 0) <= 0) return false;
    if ((values['lastName']?.length ?? 0) <= 0) return false;
    if ((values['email']?.length ?? 0) <= 0) return false;
    if (phoneNumber.length <= 0) return false;

    return true;
  };

  const handleSubmit = (event) => {
    // If the optIntoMarketing wasa not clicked, then it won't be in the values
    // map. We still want to report it though.
    if (!('optIntoMarketing' in values)) {
      values['optIntoMarketing'] = false;
    }

    console.log(JSON.stringify(values));
    console.log(values);

    const currentTimestampInSeconds = Math.floor(new Date().getTime() / 1000);
    addWaiverEntry({
      ...values,
      phoneNumber,
      timeslotId: props.timeslotId,
      timestampSeconds: currentTimestampInSeconds,
    });
    reset();

    props.onSubmit();
    event.preventDefault();
  };

  return (
    <div className="waiver-container">
      <img
        className="waiver-header-img"
        src={tikiLogo}
        alt="tiki-mirage-logo"
      />
      <div className="waiver-text">{waiverText}</div>
      <form onSubmit={handleSubmit} className="tiki-waiver-form">
        <div className="waiver-wide-inputs">
          <div className="agree-checkbox">
            <input type="checkbox" id="agree" {...bind('agree')} />
            <label htmlFor="agree">{agreeCheckBoxText}</label>
          </div>
          <div className="agree-checkbox">
            <input
              type="checkbox"
              id="marketing"
              {...bind('optIntoMarketing')}
            />
            <label htmlFor="marketing">{marketingCheckBoxText}</label>
          </div>
          <div className="waiver-name-inputs">
            <input
              className="tiki-waiver-input first-name"
              type="text"
              placeholder="First Name"
              {...bind('firstName')}
            ></input>
            <input
              className="tiki-waiver-input last-name"
              type="text"
              placeholder="Last Name"
              {...bind('lastName')}
            ></input>
          </div>
          <input
            className="tiki-waiver-input email"
            type="text"
            placeholder="Email"
            {...bind('email')}
          ></input>
          <PhoneInput
            className="tiki-waiver-input phone"
            placeholder="Phone Number"
            country="US"
            value={phoneNumber}
            onChange={setPhoneNumber}
          />
        </div>

        <button
          className="submit-button"
          type="submit"
          disabled={!isSubmitEnabled()}
        >
          I agree
        </button>
      </form>
    </div>
  );
}

export function WaiverAction(props) {
  const [submitted, setSubmitted] = useState(false);

  // Every action has a payload of data associated with it for
  // the specific action
  const actionData = props.data;
  console.log(actionData);

  if (submitted) {
    return <div className="waiver-submitted">Enjoy your stay in paradise.</div>;
  }

  return (
    <div className="waiver-action">
      <SignWaiver
        onSubmit={() => setSubmitted(true)}
        timeslotId={actionData.timeslotId}
      />
    </div>
  );
}
