import { useState } from 'react';

// Works with both text and checkbox inputs.
// If an input is not a text input, then it needs to have its type passed in
// as a parameter to the hook. {'name':'type...}
export const useSimpleInputValues = (nameToType) => {
  // object with k,v pairs 'name' -> 'value'
  const [values, setValues] = useState({});
  const [types] = useState({ ...nameToType });

  const reset = () => {
    let resetValues = {};
    for (const name in values) {
      // Then type = 'text'
      if (!(name in types)) {
        resetValues[name] = '';
      }

      // Check type; only checkbox is supported currently.
      if (types[name] === 'checkbox') {
        resetValues[name] = false;
      }
    }

    setValues(resetValues);
  };

  const bind = (name, settings = { type: 'text' }) => {
    // Check value differently based on type
    const extractValue = (event) => {
      // Then type = 'text'
      if (!(name in types)) {
        return event.target.value;
      }

      // Check type; only checkbox is supported currently.
      if (types[name] === 'checkbox') {
        return event.target.checked;
      }

      return '';
    };

    let returnValue = {
      onChange: (event) => {
        setValues({ ...values, [name]: extractValue(event) });
      },
    };

    // Then type = 'text'
    if (!(name in types)) {
      returnValue['value'] = values[name] ?? '';
    }

    // Check type; only checkbox is supported currently.
    if (types[name] === 'checkbox') {
      returnValue['checked'] = values[name] ?? false;
    }

    return returnValue;
  };

  const setValue = (name, value) => {
    setValues({ ...values, [name]: value });
  };

  return { bind, reset, values: { ...values }, setValue };
};
