import React from 'react';

function MessageTemplate(props) {
  return (
    <div
      className="message-template"
      onClick={() => props.handleActiveMessageClick(props.message)}
    >
      <div className="name">{props.message.name}</div>
      <div className="id">{props.message.id}</div>
      <div className="template">{props.message.template}</div>
    </div>
  );
}

export function MessageTemplates(props) {
  const messages = props.messages;
  const messageItems = messages.map((message) => (
    <MessageTemplate
      className="message-template"
      key={message.id}
      message={message}
      handleActiveMessageClick={props.handleActiveMessageClick}
    ></MessageTemplate>
  ));
  return <div className="message-templates">{messageItems}</div>;
}
