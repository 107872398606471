import { configureStore } from '@reduxjs/toolkit';
import messageTemplatesReducer from '../features/admin/common/messageTemplatesSlice';
import timeslotsReducer from '../features/admin/common/timeslotSlice';
import usersReducer from '../features/admin/common/usersSlice';
import sendMessageReducer from '../features/admin/sendMessage/sendMessageSlice';

export default configureStore({
  reducer: {
    users: usersReducer,
    messageTemplates: messageTemplatesReducer,
    timeslots: timeslotsReducer,
    sendMessage: sendMessageReducer,
  },
});
