import React, { useState } from 'react';
import { AdminActionManagement } from '../actions/actions';
import { AdhocMessage } from '../adhocMessage/adhocMessage';
import { Chat } from '../chat/chat';
import { Base as MessageTemplatesBase } from '../message_templates/base';
import { SendMessage } from '../sendMessage/sendMessage';
import { TimeslotPage } from '../timeslotPage/timeslotPage';
import { TockUpload } from '../tockUpload/tockUpload';
import { UserAdmin } from '../users/userAdmin';
import './adminPage.scss';

function AdminTabs(props) {
  return (
    <div className="admin-tabs">
      <div className="tab" onClick={() => props.setView(AdminActionManagement)}>
        Actions
      </div>
      <div className="tab" onClick={() => props.setView(MessageTemplatesBase)}>
        Message templates
      </div>
      <div className="tab" onClick={() => props.setView(TimeslotPage)}>
        Timeslots
      </div>
      <div className="tab" onClick={() => props.setView(UserAdmin)}>
        Users
      </div>
      <div className="tab" onClick={() => props.setView(Chat)}>
        Messages
      </div>
      <div className="tab" onClick={() => props.setView(SendMessage)}>
        Send messages
      </div>
      <div className="tab" onClick={() => props.setView(AdhocMessage)}>
        AdhocMessage
      </div>
      <div className="tab" onClick={() => props.setView(TockUpload)}>
        Tock upload
      </div>
    </div>
  );
}

export function AdminPage(props) {
  // Need to wrap functions stored as state in function because ustState and setState...
  // will call the function they are called with.
  const [selectedView, setSelectedView] = useState(() => AdminActionManagement);

  const setView = (view) => setSelectedView(() => view);

  return (
    <div className="admin-page">
      <div className="header">
        <div className="right-side">
          <div className="kiln-logo"> FLF</div>
          <div className="page-title"> Admin Page</div>
        </div>

        <div className="left-side">
          <div>logout button</div>
        </div>
      </div>
      <div className="tabs-and-view">
        <AdminTabs setView={setView}></AdminTabs>
        <div className="view"> {React.createElement(selectedView)}</div>
      </div>
    </div>
  );
}
