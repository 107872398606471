import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  createTimeslot,
  fetchTimeslots,
  selectAllTimeslots,
  updateTimeslot,
} from '../common/timeslotSlice';
import './timeslotPage.scss';

// The datetime-local input takes an input string of the format
// YYYY-MM-DDThh:mm . The only Date method to get a string of this format is
// toISOString. The issue is that toISOString returns the datetime in GMT,
// not local time. This uses the Date object passed in and puts it in a state
// so that toISOString will return a string representing datetime in local time.
function datetimeLocalConversion(date) {
  let d = new Date(date);
  d.setMinutes(d.getMinutes() - d.getTimezoneOffset());
  return d.toISOString().slice(0, 16);
}

// TODO: Properly do time lol
function EditableTimeslot(props) {
  const timeslot = props.timeslot;

  const [datetime, setDatetime] = useState(timeslot?.datetime ?? Date.now());
  const [capacity, setCapacity] = useState(timeslot?.capacity ?? 0);

  // The datetime-local input outputs a date time string of the like:
  //      2021-07-09T18:04
  // This represents local time.
  const onDatetimeChange = (newDatetime) => {
    setDatetime(new Date(newDatetime));

    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/
    // Reference/Global_Objects/Date/parse
    // "date-time forms are interpreted as local time"
    // So this will return the UTC in miliseconds.
    const utcMiliseconds = Date.parse(newDatetime);
    props.onTimeslotChange({ ...timeslot, datetime: utcMiliseconds, capacity });
  };

  const onCapacityChange = (capacity) => {
    const newCapacity = parseInt(capacity);
    setCapacity(newCapacity);
    props.onTimeslotChange({ ...timeslot, datetime, capacity: newCapacity });
  };

  return (
    <div className="editable-timeslot-inputs">
      <div>
        <input
          type="datetime-local"
          id="date-and-time"
          value={datetimeLocalConversion(datetime)}
          onChange={(e) => onDatetimeChange(e.target.value)}
        ></input>
      </div>

      <div>
        <label className="capacity-label" htmlFor="capacity">
          Capacity
        </label>
        <input
          type="number"
          id="capacity"
          value={capacity}
          onChange={(e) => onCapacityChange(e.target.value)}
        ></input>
      </div>
    </div>
  );
}

export function TimeslotPage() {
  const dispatch = useDispatch();
  const timeslots = useSelector(selectAllTimeslots);
  const timeslotStatus = useSelector((state) => state.timeslots.status);

  // Cache changes to timeslots so that when update timeslots is clicked
  // we can send all updates.
  let timeslotChanges = {};
  const onTimeslotChange = (timeslot) =>
    (timeslotChanges[timeslot.id] = timeslot);

  // Load timeslots on component load.
  useEffect(() => {
    if (timeslotStatus === 'idle') {
      dispatch(fetchTimeslots());
    }
  }, [timeslotStatus, dispatch]);

  const onCreateTimeslot = () => {
    dispatch(createTimeslot({}));
  };

  const onUpdateTimeslot = () => {
    for (const timeslotId in timeslotChanges) {
      dispatch(updateTimeslot(timeslotChanges[timeslotId]));
    }
    timeslotChanges = {};
  };

  const editableTimeslots = timeslots.map((timeslot) => (
    <EditableTimeslot
      key={timeslot.id}
      timeslot={timeslot}
      onTimeslotChange={onTimeslotChange}
    />
  ));

  return (
    <div className="timeslot-page">
      <h1> Timeslot Page</h1>
      <h2>Active timeslots</h2>
      {editableTimeslots}
      <button onClick={(e) => onCreateTimeslot()}> Create timeslot</button>
      <button onClick={(e) => onUpdateTimeslot()}> Update timeslots</button>
    </div>
  );
}
