import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  addNewMessage,
  fetchTemplates,
  selectAllMessages,
} from '../common/messageTemplatesSlice';
import { ActiveMessage } from './active_message';
import './base.scss';
import { MessageTemplates } from './message_templates';

function isEmpty(obj) {
  return Object.keys(obj || {}).length === 0;
}

// Returns true if filterFunction evaluates to true for any element of the array.
function contains(array, filterFunction) {
  for (let i = 0; i < array.length; i++) {
    if (filterFunction(array[i])) return true;
  }
  return false;
}

function ActiveMessageOrEmpty(props) {
  if (isEmpty(props.message)) {
    return <div> No messages!</div>;
  }
  return (
    <ActiveMessage
      message={props.message}
      key={props.message.id}
    ></ActiveMessage>
  );
}

export function Base() {
  const dispatch = useDispatch();
  const messages = useSelector(selectAllMessages);
  const messageStatus = useSelector((state) => state.messageTemplates.status);

  // Load messages on component load.
  useEffect(() => {
    if (messageStatus === 'idle') {
      dispatch(fetchTemplates());
    }
  }, [messageStatus, dispatch]);

  const [activeMessage, setActiveMessage] = useState(messages[0] ?? {});

  // Set the active message to the first message on initialization.
  if (isEmpty(activeMessage)) {
    if (messages.length > 0) {
      setActiveMessage(messages[0]);
    }
    // Do nothing, because there are no messages
  } else {
    // Might need to update the active message if it was deleted.
    if (!contains(messages, (message) => message.id === activeMessage.id)) {
      setActiveMessage(messages[0] ?? {});
    }
  }

  return (
    <div className="base">
      <button
        className="new-template-button"
        onClick={() => dispatch(addNewMessage())}
      >
        New message template
      </button>
      <div className="message-area">
        <MessageTemplates
          messages={messages}
          handleActiveMessageClick={setActiveMessage}
        ></MessageTemplates>
        <ActiveMessageOrEmpty message={activeMessage}></ActiveMessageOrEmpty>
      </div>
    </div>
  );
}
