import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/functions';
import { firebaseConfig } from './firebase_config.js';

// Only init if not arleady.
if (firebase.apps.length === 0) {
  firebase.initializeApp(firebaseConfig);
}

if (window.location.hostname === 'localhost') {
  var auth = firebase.auth();
  auth.useEmulator('http://localhost:9099');

  // Also make functions use local emulator!
  firebase.functions().useEmulator('localhost', 5001);
}

// Returns the current user or null if not signed in.
export function currentUser() {
  return firebase.auth().currentUser;
}

export async function createUserWithEmail(email, password) {
  return firebase
    .auth()
    .createUserWithEmailAndPassword(email, password)
    .then((userCredential) => {
      // Signed in
      var user = userCredential.user;
      console.log(user);
      return user;
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.error(errorCode);
      console.error(errorMessage);
    });
}

export async function signInWithEmailandPassword(email, password) {
  return firebase
    .auth()
    .signInWithEmailAndPassword(email, password)
    .then((userCredential) => {
      // Signed in
      var user = userCredential.user;
      return { success: true, user };
    })
    .catch((error) => {
      return {
        hasError: true,
        error: { errorCode: error.code, errorMessage: error.message },
      };
    });
}

export async function signOut() {
  return firebase.auth().signOut();
}

export function onAuthStateChanged(callback) {
  return firebase.auth().onAuthStateChanged(callback);
}
