import firebase from 'firebase/app';
import 'firebase/functions';
import { firebaseConfig } from './firebase_config.js';

// Only init if not arleady.
if (firebase.apps.length === 0) {
  firebase.initializeApp(firebaseConfig);
}

const sendTextFunction = firebase.functions().httpsCallable('sendText');
export async function sendText(phoneNumber, message, userId) {
  return sendTextFunction({
    toNumber: phoneNumber,
    text: message,
    userId,
  })
    .then((result) => {
      return {
        hasSuccess: true,
        success: {
          message: result.data.successMessage,
        },
      };
    })
    .catch((error) => {
      return {
        hasError: true,
        error: {
          code: error.code,
          message: error.message,
          details: error.details,
        },
      };
    });
}

const loadActionFunction = firebase.functions().httpsCallable('loadAction');
export async function loadAction(actionId) {
  if (actionId === undefined) return;

  try {
    const action = await loadActionFunction({ actionId });
    return {
      hasSuccess: true,
      success: {
        action: action.data,
      },
    };
  } catch (error) {
    return {
      hasError: true,
      error: {
        code: error.code,
        message: error.message,
        details: error.details,
      },
    };
  }
}
