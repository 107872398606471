import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { sendText } from '../../../api/cloudFunctions';
import { subscribeToUserMessages } from '../../../api/firestore';
import { fetchUsers, selectAllUsers } from '../common/usersSlice';
import './chat.scss';

function UserList(props) {
  const userItems = props.users.map((user) => (
    <div
      className="user-item"
      onClick={() => props.onSelectUser(user)}
      key={user.id}
    >
      {user.firstName} {user.lastName}
    </div>
  ));

  return <div className="user-list">{userItems}</div>;
}

function Messages(props) {
  if (!props.messages) return <div className="messages" />;

  const htmlChatItems = props.messages.map((message) => {
    let classNames = ['chat-item'];
    if (message.sender === 'user') {
      classNames.push('from-user');
    } else {
      classNames.push('to-user');
    }
    classNames = classNames.join(' ');

    return (
      <div className={classNames} key={message.id}>
        {message.content}
      </div>
    );
  });

  return <div className="messages">{htmlChatItems}</div>;
}

function SendMessage(props) {
  // TODO: Display error properly.
  const [message, setMessage] = useState('');

  const handleSubmit = (event) => {
    // Sends a text message which is saved to the user's messages.
    // This will automatically cause the message to be added to
    // the UI since we listen to updates to that collection.
    sendText(props.user.phoneNumber, message, props.user.id);

    setMessage('');
    event.preventDefault();
  };

  return (
    <div className="send-message">
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          value={message}
          onChange={(event) => setMessage(event.target.value)}
        ></input>
        <input type="submit" value="Send"></input>
      </form>
    </div>
  );
}

function ChatView(props) {
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    if (!props.selectedUser) return;

    function handleMessagesChange(msgs) {
      // sorts in chronological order.
      msgs.sort((a, b) => a.datetime.seconds - b.datetime.seconds);
      setMessages(msgs);
    }

    return subscribeToUserMessages(props.selectedUser.id, handleMessagesChange);
  }, [props?.selectedUser]);

  if (!props.selectedUser) {
    // If no user passed in yet, don't try to render chat.
    return <div className="chat-view" />;
  }

  return (
    <div className="chat-view">
      <Messages messages={messages} /> <SendMessage user={props.selectedUser} />
    </div>
  );
}

export function Chat(props) {
  const dispatch = useDispatch();
  const users = useSelector(selectAllUsers);
  const usersStatus = useSelector((state) => state.users.status);

  const [selectedUser, setSelectedUser] = useState(undefined);

  const onSelectUser = (user) => {
    setSelectedUser(user);
  };

  // Load users on component load.
  useEffect(() => {
    if (usersStatus === 'idle') {
      dispatch(fetchUsers());
    }
  }, [usersStatus, dispatch]);

  return (
    <div>
      <h3>Chat</h3>
      <div className="chat-container">
        <UserList users={users} onSelectUser={onSelectUser} />
        <ChatView selectedUser={selectedUser} />
      </div>
    </div>
  );
}
