import firebase from 'firebase/app';
import 'firebase/firestore';
import { firebaseConfig } from './firebase_config.js';

// Only init if not arleady.
if (firebase.apps.length === 0) {
  firebase.initializeApp(firebaseConfig);
}

var db = firebase.firestore();
if (window.location.hostname === 'localhost') {
  db.useEmulator('localhost', 8080);
}

//////////////////////////////////////
// Message templates
//////////////////////////////////////

// Load message templates
export async function loadMessageTemplates() {
  let data = [];
  try {
    let querySnapshot = await db.collection('messageTemplates').get();
    for (const doc of querySnapshot.docs) {
      data.push({ ...doc.data(), id: doc.id });
    }

    return data;
  } catch (error) {
    console.error('Error loading all message templates: ', error);
  }
}

// Add message template
// On success, returns an object with the new id:
//   { id: new_id }
export async function addMessageTemplate(template) {
  // Add some validation for message template? Probably will be covered with
  // typescript

  let docRef;
  try {
    docRef = await db.collection('messageTemplates').add(template);
    let data = await docRef.get();
    return { id: data.id };
  } catch (error) {
    console.error('Error adding document: ', error);
  }
  console.log('Document written with Id: ', docRef.id);
}

// Update message template
export async function updateMessageTemplate(template) {
  // Maybe don't merge, because we can't delete individual fields

  let doc;
  try {
    doc = db.collection('messageTemplates').doc(template.id);
    await doc.set({ ...template }, { merge: true });
  } catch (error) {
    console.error('Error updating document: ', error);
  }
  console.log('Document updated with Id: ', doc.id);
}

// Delete message template
export async function deleteMessageTemplate(template) {
  try {
    await db.collection('messageTemplates').doc(template.id).delete();
  } catch (error) {
    console.error('Error deleting document: ', error);
  }
  console.log('Document deleted with Id: ', template?.id);
}

//////////////////////////////////////
// Users
//////////////////////////////////////

// Load all users in the Users collection.
//
// These are not users that have authenticated accounts, but
// users who are guests to events. We have a name and phonenumber
// to communicate with them.
export async function loadUsers() {
  try {
    let querySnapshot = await db.collection('users').get();

    let data = [];
    for (const doc of querySnapshot.docs) {
      data.push({ ...doc.data(), id: doc.id });
    }

    return data;
  } catch (error) {
    console.error('Error loading all users: ', error);
  }
}

export async function getUserIdWithPhoneNumber(phonenumber) {
  try {
    let user = await db
      .collection('users')
      .where('phoneNumber', '==', phonenumber)
      .get();

    // No user found
    if (user.empty) return null;
    if (user.size > 1)
      throw new Error(
        'Returned more than one user document for a phone number'
      );

    return user.docs[0].id;
  } catch (error) {
    console.error('Error loading all users: ', error);
  }
}

// Create a user in the users collection
//
// On success returns an id for the user.
export async function createUser(user) {
  let docRef;
  try {
    docRef = await db.collection('users').add(user);
    let data = await docRef.get();
    return data.id;
  } catch (error) {
    console.error('Error adding document: ', error);
  }
  console.log('Document written with Id: ', docRef.id);
}

//////////////////////////////////////
// Timeslots
//////////////////////////////////////

// Load all timeslots in the Timeslots collection.
//
// Timeslots define a time for an event to take place as well as a capacity.
// If the datetime field is set, it is parsed out as utc seconds.
export async function loadTimeslots() {
  let data = [];
  try {
    let querySnapshot = await db.collection('timeslots').get();
    for (const doc of querySnapshot.docs) {
      const timeslot = { ...doc.data(), id: doc.id };

      data.push(timeslot);
    }

    return data;
  } catch (error) {
    console.error('Error loading all timeslots: ', error);
  }
}

// Create a timeslot in the timeslots collection
//
// On success returns an id for the timeslot
export async function createTimeslot(timeslot) {
  let docRef;
  try {
    docRef = await db.collection('timeslots').add(timeslot);
    let data = await docRef.get();
    return data.id;
  } catch (error) {
    console.error('Error adding document: ', error);
  }
  console.log('Document written with Id: ', docRef.id);
}

export async function updateTimeslot(timeslot) {
  // Maybe don't merge, because we can't delete individual fields

  let doc;
  try {
    doc = db.collection('timeslots').doc(timeslot.id);

    // Don't want to save the id field in the document.
    delete timeslot.id;

    await doc.set({ ...timeslot }, { merge: true });
  } catch (error) {
    console.error('Error updating document: ', error);
  }
  console.log('Document updated with Id: ', doc.id);
}

//////////////////////////////////////
// Actions
//////////////////////////////////////

// Create an action in the actions collection
//
// On success returns an id for the action
export async function createAction(action) {
  let docRef;
  try {
    docRef = await db.collection('actions').add(action);
    let data = await docRef.get();
    return data.id;
  } catch (error) {
    console.error('Error adding document: ', error);
  }
}

//////////////////////////////////////
// Messages (persistenting for chat)
//////////////////////////////////////

// message defined as:
//  * content - string
//  * type - "text"
//  * datetime unix - number
//  * sender - "user", "FLF"
export async function addMessage(userId, message) {
  let docRef;
  try {
    docRef = await db
      .collection('users')
      .doc(userId)
      .collection('messages')
      .add(message);
  } catch (error) {
    console.error('Error adding message: ', error);
    return { hasError: true, error };
  }
  console.log('Document written with Id: ', docRef.id);
  return { success: true, messageId: docRef.id };
}

export async function selectMessagesForUser(userId) {
  let messages = [];
  try {
    let querySnapshot = await db
      .collection('users')
      .doc(userId)
      .collection('messages')
      .get();
    for (const doc of querySnapshot.docs) {
      const message = { ...doc.data(), id: doc.id };

      messages.push(message);
    }

    return messages;
  } catch (error) {
    console.error(`Error loading all messages for user ${userId}: `, error);
  }
}

// Subscribe to changes to a user's messages collection.
// Returns an unsubscribe function
// onChange is called with the entire contents of the collection.
export function subscribeToUserMessages(userId, onChange) {
  return db
    .collection('users')
    .doc(userId)
    .collection('messages')
    .onSnapshot((querySnapshot) => {
      let messages = [];
      for (const doc of querySnapshot.docs) {
        const message = { ...doc.data(), id: doc.id };
        messages.push(message);
      }

      onChange(messages);
    });
}

//////////////////////////////////////
// Waitlist
//////////////////////////////////////

// Adds an entry to the waitlist.
//
// On success returns an id for the new document.
export async function addWaitlistEntry(waitlistEntry) {
  let docRef;
  try {
    docRef = await db.collection('waitlist').add(waitlistEntry);
    let data = await docRef.get();
    return data.id;
  } catch (error) {
    console.error('Error adding document: ', error);
  }
  console.log('Document written with Id: ', docRef);
  console.log('Document written with Id: ', docRef.id);
}

//////////////////////////////////////
// Waiver action
//////////////////////////////////////

// Adds an entry to the waiver collection.
//
// On success returns an id for the document.
export async function addWaiverEntry(waiverEntry) {
  let docRef;
  try {
    docRef = await db.collection('waiver').add(waiverEntry);
    let data = await docRef.get();
    return data.id;
  } catch (error) {
    console.error('Error adding document: ', error);
  }
  console.log('Document written with Id: ', docRef);
  console.log('Document written with Id: ', docRef.id);
}
