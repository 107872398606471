import { SocialIcon } from 'react-social-icons';
import joinWaitlistBanner from '../joinWaitlist/assets/joinWaitlist.png';
import { JoinWaitlist } from '../joinWaitlist/joinWaitlist';
import bannerImage from './TikiMirageBanner_small.png';
import bgMusic from './tiki.mp3';
import './tikiHome.scss';

var Scroll = require('react-scroll');
var scroll = Scroll.animateScroll;

export function TikiHome() {
  return (
    <body bgcolor="#396a4e">
      <div class="reserve-banner-m" onClick={handleClick}>
        <img
          className="reserve-img"
          src={joinWaitlistBanner}
          alt="Book your stay in Paradise"
        />
      </div>
      <div className="page">
        <div className="banner-wrapper">
          <img className="banner" src={bannerImage} alt="Tiki Mirage" />
        </div>
        <div className="ballad-container">
          <div className="ballad-title">
            <b>THE BALLAD OF DANIEL T. COLERIDGE</b>
            <hr color="#ecd9ac" width="60%"></hr>
          </div>
          <div className="ballad-column-container">
            <div className="ballad-column">
              From one small town which had no name<br></br>
              &emsp;A young man did embark<br></br>
              Aboard a merchant trading ship<br></br>
              &emsp;For lands both lush and stark.<br></br>
              <br></br>
              The man had never traveled far<br></br>
              &emsp;Beyond his humble home,<br></br>
              So awed and wonderstruck was he<br></br>
              &emsp;As ‘round the world he roamed.<br></br>
              <br></br>
              But as the man traversed the globe,<br></br>
              &emsp;He had a creeping fear<br></br>
              That each new place would slowly seem<br></br>
              &emsp;Repetitive and drear.<br></br>
              <br></br>
              Thus every time the ship would dock,<br></br>
              &emsp;The young man would alight<br></br>
              To gather keepsakes that recall<br></br>
              &emsp;The wondrous sounds and sights.<br></br>
              <br></br>
              His large collection soon outgrew<br></br>
              &emsp;His narrow sleeping quarters,<br></br>
              So secretly he stowed it in<br></br>
              &emsp;One of the ship’s containers.
            </div>
            <div className="ballad-column">
              This box was numbered 83<br></br>
              &emsp;And was the man’s great pride,<br></br>
              So hesitant at first was he<br></br>
              &emsp;To let people inside.<br></br>
              <br></br>
              But over time, the man began<br></br>
              &emsp;To welcome guests——though few——<br></br>
              To have a drink inside this box<br></br>
              &emsp;With friends and strangers, too.<br></br>
              <br></br>
              In plain sight now the box is hid<br></br>
              &emsp;Disguised in camouflage,<br></br>
              So only those with piercing eyes<br></br>
              &emsp;Discern Tiki Mirage.<br></br>
              <br></br>
              Where Daniel Coleridge is today<br></br>
              &emsp;No man can say for certain,<br></br>
              But still some guests are asked to peek<br></br>
              &emsp;Behind the bamboo curtain.<br></br>
              <br></br>
              To you, Dear Reader, thus I give<br></br>
              &emsp;A special invitation:<br></br>
              Please join us at Tiki Mirage<br></br>
              &emsp;For tasty, sweet libations.
            </div>
          </div>
        </div>
        <div className="ballad-container-m">
          <div className="ballad-title">
            <b>THE BALLAD OF DANIEL T. COLERIDGE</b>
            <hr color="#ecd9ac" width="60%"></hr>
          </div>
          <div className="ballad-body-m">
            From one small town which had no name<br></br>
            &emsp;A young man did embark<br></br>
            Aboard a merchant trading ship<br></br>
            &emsp;For lands both lush and stark.<br></br>
            <br></br>
            The man had never traveled far<br></br>
            &emsp;Beyond his humble home,<br></br>
            So awed and wonderstruck was he<br></br>
            &emsp;As ‘round the world he roamed.<br></br>
            <br></br>
            But as the man traversed the globe,<br></br>
            &emsp;He had a creeping fear<br></br>
            That each new place would slowly seem<br></br>
            &emsp;Repetitive and drear.<br></br>
            <br></br>
            Thus every time the ship would dock,<br></br>
            &emsp;The young man would alight<br></br>
            To gather keepsakes that recall<br></br>
            &emsp;The wondrous sounds and sights.<br></br>
            <br></br>
            His large collection soon outgrew<br></br>
            &emsp;His narrow sleeping quarters,<br></br>
            So secretly he stowed it in<br></br>
            &emsp;One of the ship’s containers.<br></br>
            <br></br>
            This box was numbered 83
            <br></br>
            &emsp;And was the man’s great pride,<br></br>
            So hesitant at first was he<br></br>
            &emsp;To let people inside.<br></br>
            <br></br>
            But over time, the man began<br></br>
            &emsp;To welcome guests——though few——<br></br>
            To have a drink inside this box<br></br>
            &emsp;With friends and strangers, too.<br></br>
            <br></br>
            In plain sight now the box is hid<br></br>
            &emsp;Disguised in camouflage,<br></br>
            So only those with piercing eyes<br></br>
            &emsp;Discern Tiki Mirage.<br></br>
            <br></br>
            Where Daniel Coleridge is today<br></br>
            &emsp;No man can say for certain,<br></br>
            But still some guests are asked to peek<br></br>
            &emsp;Behind the bamboo curtain.<br></br>
            <br></br>
            To you, Dear Reader, thus I give<br></br>
            &emsp;A special invitation:<br></br>
            Please join us at Tiki Mirage<br></br>
            &emsp;For tasty, sweet libations.
          </div>
        </div>
        <div>
          <div className="contact-us">
            <JoinWaitlist></JoinWaitlist>
            <div className="social-icons">
              <SocialIcon
                className="instagram-icon"
                bgColor="#ecd9ac"
                url="https://www.instagram.com/tikimirage"
              />
              <SocialIcon
                className="tiktok-icon"
                bgColor="#ecd9ac"
                url="https://www.tiktok.com/@tikimirage"
              />
            </div>
          </div>
          <footer className="book-link-footer">
            <a
              className="book-link"
              href="https://www.exploretock.com/thunderbolt/"
              target="_blank"
              rel="noreferrer"
            >
              Tickets for Thunderbolt at Tiki Mirage
            </a>
            <div className="text-stylng or">or</div>
            <a href="mailto:firstmate@tikimirage.com" className="text-stylng">
              Email us for private events at Tiki Mirage
            </a>
          </footer>
        </div>
        <iframe
          title="bgm"
          src={bgMusic}
          className="bg-music"
          loop="true"
          allow="autoplay"
          id="iframeAudio"
        ></iframe>
      </div>
    </body>
  );
}

function handleClick() {
  scroll.scrollToBottom({
    duration: 500,
    smooth: 'easeInOutQuint',
  });
}
