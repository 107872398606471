import { useState } from 'react';
import { sendText } from '../../../api/cloudFunctions';
import './adhocMessage.scss';

function RpcStateDisplay(props) {
  const rpcState = props.rpcState;

  if (rpcState.hasSuccess) {
    return (
      <div className="rpc-state-display">
        <div>{rpcState.success.message}</div>
      </div>
    );
  }

  if (rpcState.hasError) {
    return (
      <div className="rpc-state-display">
        <div>code: {rpcState.error.code}</div>
        <div>message: {rpcState.error.message}</div>
        <div>details: {rpcState.error.details}</div>
      </div>
    );
  }

  return '';
}

export function AdhocMessage(props) {
  // Probably don't need these state variables and could just
  // use the values from the form
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');
  const [rpcState, setRpcState] = useState({});

  const handleSubmit = (event) => {
    sendText(phone, message).then((result) => setRpcState(result));

    setPhone('');
    setMessage('');

    event.preventDefault();
  };

  return (
    <div>
      <h3>Adhoc message</h3>
      <form onSubmit={handleSubmit}>
        <div className="label-input">
          <label htmlFor="phone">Phone number</label>
          <input
            id="phone"
            type="text"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          ></input>
        </div>

        <div className="label-input">
          <label htmlFor="message">Message</label>
          <input
            id="message"
            type="text"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          ></input>
        </div>

        <input className="adhoc-msg-submit-button" type="submit"></input>
      </form>
      <RpcStateDisplay rpcState={rpcState} />
    </div>
  );
}
